import { logErrorIfElementIsMissing } from '../modal_handler/utils';
import { ScrollLockStore } from '@/components/modals/types';
import Alpine from 'alpinejs';

class ModalStore {
    existingModals: string[];
    visibleModals: Map<string, boolean>;
    uncachedModals: Map<string, boolean>;
    constructor() {
        this.existingModals = [];
        this.visibleModals = new Map();
        this.uncachedModals = new Map();
    }

    hasExistingModal(id: string): boolean {
        return this.existingModals.includes(id);
    }

    populateUncachedModals(id: string): void {
        this.uncachedModals.set(id, true);
    }

    populateModals(id: string): void {
        this.existingModals.push(id);
    }

    openModal(id: string): void {
        if (!id) {
            logErrorIfElementIsMissing(id);
            return;
        }

        (Alpine.store('scrollLock') as ScrollLockStore).setBodyScrollable(true);
        this.visibleModals.set(id, true);
    }

    closeModal(id: string): void {
        if (!id) {
            logErrorIfElementIsMissing(id);
            return;
        }

        (Alpine.store('scrollLock') as ScrollLockStore).setBodyScrollable(false);
        this.visibleModals.delete(id);
        if (this.uncachedModals.has(id)) {
            let modalToDelete = document.querySelector(`.${id}`);
            setTimeout(() => {
                modalToDelete.remove();
            }, 250);
        }
    }
}

Alpine.store('modals', new ModalStore());

export const modalStore = Alpine.store('modals') as ModalStore;
