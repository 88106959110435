import { handleModalState } from '@/components/modals/utils';

document.addEventListener('htmx:confirm', (event) => {
    event.preventDefault();
    
    const feature = event.target.dataset.feature;

    if (feature) {
        // @TODO: Implement check_login with the new stack
        if (!check_login(feature)) return;
    }

    if (handleModalState(event.target.dataset.targetmodal, event.target.dataset.cache)) return;

    event.detail.issueRequest();
});
