import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';

import 'htmx.org';

import '@/events';

import { initPageComponent, initSharedComponents } from './load_components';

async function init() {
    window.Alpine = Alpine;

    await initSharedComponents();
    await initPageComponent();

    Alpine.plugin(intersect);
    Alpine.start();
}

init();
