async function initSharedComponents() {
    await import('./shared_components');
}

async function initPageComponent() {
    const pageId = document.body.dataset.pageId;

    switch (pageId) {
        case 'pornstar':
            await import('./pornstar');
            break;
        case 'creator_details':
            await import('./creator_details');
            break;
        case 'edit_creator_account':
            await import('./edit_creator_account');
            break;
        case 'gif_page':
        case 'gif_page_detail':
        case 'gif_subcategory_page':
        case 'gif_newest_page':
            await import('./gif');
            break;
        case 'identity_verification_info':
            await import('./identity_verification_info');
            break;
    }
}

export { initSharedComponents, initPageComponent };
