function logErrorIfElementIsMissing(id) {
    console.error(`Element with data-id: ${id} not found`);
}

function populateModalObject(elements) {
    const obj = {};
    elements.forEach((e) => {
        if (!e.dataset.id) {
            console.error('Attribute: data-id is missing');
            return;
        }

        let id = e.dataset.id;
        obj[id] = { isVisible: false };
    });

    return obj;
}

export { logErrorIfElementIsMissing, populateModalObject };
