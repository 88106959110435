import { modalStore } from '@/components/modals/modals';

/**
 * Handles the state of a modal based on the provided ID and cache.
 *
 * When the cache is null (i.e., the button that was clicked does not have the data-cache attribute),
 * the function populates the uncached modal and returns false, allowing the AJAX request to proceed.
 *
 * If the button has the data-cache attribute, the function checks whether the modal to be opened
 * is already in the DOM. If it is, the function delineates the modal and returns true,
 * preventing the AJAX request from proceeding (avoiding reloading the modal).
 *
 * In all other cases, the function returns false, allowing the AJAX request to proceed.
 *
 */
const handleModalState = (id?: string, cache?: string): boolean => {
    if (!id) return false;

    if (cache == null) {
        modalStore.populateUncachedModals(id);
        return false;
    }

    if (modalStore.hasExistingModal(id)) {
        modalStore.openModal(id);
        return true;
    }

    return false;
};

const handleModalAfterRequest = (id: string | null | undefined): void => {
    if (!id) {
        return;
    }
    modalStore.populateModals(id);
    modalStore.openModal(id);
};

export { handleModalState, handleModalAfterRequest };
